import { useCallback } from "react";

import { useNetworkCreationAmplitudeEvent } from "../../hooks/useNetworkCreationAmplitudeEvent";

import { usePostFileUploadMutation, 
  usePostFileProcessMutation} from "../../api/j2/j2Api";
export function useProcessSelfServeData() {
  // self serve upload API request
  const [postFileUpload, fileUploadResult] = usePostFileUploadMutation();
  // self serve process API request
  const [postFileProcess, fileProcessResult] = usePostFileProcessMutation();

  const trackNetworkCreationAmplitudeEvent = useNetworkCreationAmplitudeEvent();

  const processingPaused =
    fileProcessResult.isUninitialized && hasDomainError(fileUploadResult.data);

  const canResumeProcessing = needsUserAction(
    fileUploadResult.data,
    fileProcessResult.isUninitialized
  );

  const inProgress =
    fileUploadResult.isFetching || fileProcessResult.isFetching;

  const isSuccess = fileProcessResult.isSuccess;

  const isFetchError = fileUploadResult.isError || fileProcessResult.isError;

  const errorMessage = isFetchError
    ? fileProcessResult.error?.data?.message ??
      fileUploadResult.error?.data?.message ??
      (fileUploadResult.error?.status === "FETCH_ERROR"
        ? "The file you selected has its content changed. Please select it again before submitting"
        : "Error when processing file!")
    : null;

  const submitProcessing = useCallback(
    async (data) => {
      fileProcessResult.reset();
      fileUploadResult.reset();
      const action = data.updateNetworkId ? "update" : "creation";
      try {
        let result = { Errors: [], Fatals: [] };
        if (data.file) {
          result = await postFileUpload(data).unwrap();
        }
        if (
          result.Fatals.length === 0 &&
          result.Errors.length === 0 &&
          !needsUserAction(result, true)
        ) {
          const { Columns, ExtraColumns, ValidFileName } = result;
          await postFileProcess({
            ...data,
            Columns,
            ExtraColumns,
            file: { name: ValidFileName },
          }).unwrap();
          trackNetworkCreationAmplitudeEvent(`Complete network ${action}`, { ...data });
        }
      } catch (error) {
        trackNetworkCreationAmplitudeEvent(`Fail network ${action}`, { ...data, ...error });
        console.error(error?.data?.message ?? error);
      }
    },
    [fileProcessResult, fileUploadResult, postFileProcess, postFileUpload, trackNetworkCreationAmplitudeEvent]
  );

  const proceedProcessing = useCallback(async () => {
    const action = fileUploadResult.originalArgs?.updateNetworkId ? "update" : "creation";
    try {
      const { Columns, ExtraColumns, ValidFileName } = fileUploadResult.data;
      await postFileProcess({
        ...fileUploadResult.originalArgs,
        Columns,
        ExtraColumns,
        file: { name: ValidFileName },
      }).unwrap();
      trackNetworkCreationAmplitudeEvent(`Complete network ${action}`, { ...fileUploadResult.originalArgs });
    } catch (error) {
      trackNetworkCreationAmplitudeEvent(`Complete network ${action}`, { ...fileUploadResult.originalArgs });
      console.error(error?.data?.message ?? error);
    }
  }, [fileUploadResult.data, fileUploadResult.originalArgs, postFileProcess, trackNetworkCreationAmplitudeEvent]);

  return {
    submitProcessing,
    proceedProcessing,
    fileUploadResult,
    fileProcessResult,
    processingPaused,
    canResumeProcessing,
    inProgress,
    isSuccess,
    isFetchError,
    errorMessage,
  };
}

function needsUserAction(data, isUninitialized) {
  return (
    isUninitialized &&
    !(data?.Fatals?.length > 0) &&
    (data?.Errors?.length > 0 || data?.Warnings?.length > 0)
  );
}

function hasDomainError(data) {
  return (
    data?.Fatals?.length > 0 ||
    data?.Errors?.length > 0 ||
    data?.Warnings?.length > 0
  );
}
