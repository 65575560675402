import { useSelector } from "react-redux";
import {
  useGetCountyShapesQuery,
  useGetZipShapesQuery,
  // useGetProviderLocationsQuery,
  // useGetProviderLocationScoresQuery,
  useGetZipScoresQuery,
  useGetProviderLocationTagsQuery,
  // useGetProvidersQuery,
  useGetZipcodeMetricsQuery,
  useGetZipGapsQuery,
  useGetProviderUniverseQuery,
  useGetProviderStatusesQuery,
  useGetProviderScoresQuery,
  useGetZipcodeMetricValuesQuery,
  useGetMemberServedQuery,
} from "../api/networksApi";
import { J2Map } from "../legacy-components/J2Map";
import { ProviderGrid } from "../components/ProviderGrid/ProviderGrid";
import { selectSelectedNetwork } from "../features/networks";
import {
  selectEditedProviders,
  selectHideAllFilterVal,
  selectSpecialtyFilterVal,
} from "../features/providers";
import { useRef } from "react";
import { DataPanel } from "../components/DataPanel/DataPanel";
import { ApiActivityProgress } from "../components/ApiActivityProgress";
import { NetworkStatusFilter } from "../components/CustomFilters/NetworkStatusFilter";
import { ExportMapButton } from "../components/ExportMapButton";
import { WhenNetworks } from "../components/WhenNetworks";
import { LayerLegend } from "../components/MapControl/LayerLegend";
import { selectMapTheme } from "../features/map";
import { selectZipcodeMetrics } from "../features/layersData";

const VIEWPORT = {
  SPLIT: 68,
  FULL: 99,
};

export function MapRoute() {
  const network = useSelector(selectSelectedNetwork);
  const networkId = network?.id;
  const specialtyId = useSelector(selectSpecialtyFilterVal);
  const theme = useSelector(selectMapTheme);
  const loadingParams = !network || !specialtyId;

  const isFullView = useSelector(selectHideAllFilterVal);
  const viewSplit = isFullView ? VIEWPORT.FULL : VIEWPORT.SPLIT;

  const skipLoadingProviders = loadingParams || isFullView;

  const mapRef = useRef();

  const { isFetching: isFetchingMemberServed } = useGetMemberServedQuery(
    { networkId, specialtyId },
    { skip: !network }
  );

  const { isFetching: isFetchingProviderUniverse } =
    useGetProviderUniverseQuery(
      { networkId, specialtyId },
      { skip: skipLoadingProviders }
    );

  const { isFetching: isFetchingProviderLocationScores } =
    useGetProviderScoresQuery(
      { networkId, specialtyId },
      { skip: skipLoadingProviders }
    );

  const { isFetching: isFetchingProviderInStatus } =
    useGetProviderStatusesQuery({ networkId }, { skip: skipLoadingProviders });

  const { isFetching: isFetchingProviderLocationTags } =
    useGetProviderLocationTagsQuery(networkId, { skip: !network });

  const { isFetching: isFetchingProviderBaselineInStatus } =
    useGetProviderStatusesQuery(
      { networkId, baseline: 1 },
      { skip: !networkId }
    );

  useGetZipScoresQuery({ networkId, specialtyId }, { skip: loadingParams });

  useGetCountyShapesQuery(networkId, { skip: !network });
  useGetZipShapesQuery(networkId, { skip: !network });

  useGetZipcodeMetricsQuery(networkId, { skip: !network });
  useGetZipGapsQuery(networkId, { skip: theme.metric_id !== "gapsTotal" });

  const zipcodeMetrics = useSelector(selectZipcodeMetrics);

  const baselineChanges = useSelector(selectEditedProviders);

  useGetZipcodeMetricValuesQuery(
    { networkId, metricId: theme.metric_id },
    { skip: !zipcodeMetrics.find((m) => m.metric_id === theme.metric_id) }
  );

  const resizeMap = mapRef?.current?.resize
    ? () => setTimeout(() => mapRef?.current?.resize(), 1)
    : null;

  return (
    <WhenNetworks>
      <div className="mapOverview" style={{ "--mapSplit": `${viewSplit}vh` }}>
        <J2Map ref={mapRef} />

        {viewSplit < VIEWPORT.FULL && (
          <div className="providerGrid">
            <ProviderGrid
              loading={
                isFetchingProviderUniverse ||
                isFetchingProviderLocationScores ||
                isFetchingProviderInStatus ||
                isFetchingProviderLocationTags ||
                isFetchingMemberServed ||
                isFetchingProviderBaselineInStatus
              }
              onFilterChange={mapRef?.current?.setGridFilteredIds}
              baselineChanges={baselineChanges}
            />
          </div>
        )}
        <div className="floating-bar">
          <DataPanel
            slots={[
              <NetworkStatusFilter onNoneButtonToggle={resizeMap} />,
              <ExportMapButton
                mapRef={mapRef}
                network={network}
                theme={theme}
              />,
            ]}
          />
        </div>

        <LayerLegend
          small
          id="layer-legend"
          sx={{
            position: "absolute",
            zIndex: 3,
            top: 56,
            left: 8,
            background: "rgba(255,255,255,0.8)",
            padding: 2,
            width: "fit-content",
          }}
        />
        <ApiActivityProgress />
      </div>
    </WhenNetworks>
  );
}
