
//
// POST /file/update
//

export const postFileUpdateApi = (build) =>
  build.mutation({
    query: (body) => ({
      method: "POST",
      url: "/v2/portal/file/update",
      body,
    }),
  });

//
// POST /file/merge
//

export const postFileMergeApi = (build) =>
  build.mutation({
    query: (body) => ({
      method: "POST",
      url: "/v2/portal/file/merge",
      body,
    }),
  });
