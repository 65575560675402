import { useSelector } from "react-redux";
import {
  useGetMemberServedQuery,
  useGetProviderLocationTagsQuery,
  useGetProviderScoresQuery,
  useGetProviderStatusesQuery,
  useGetProviderUniverseQuery,
} from "../api/networksApi";
import { ProviderTable } from "../components/ProviderTable/ProviderTable";
import { Typography } from "@mui/material";
import { selectSelectedNetworkId } from "../features/selectedNetwork";
import { WhenNetworks } from "../components/WhenNetworks";
import { selectEditedProviders } from "../features/providers";

const modeTitleMap = {
  DisplayCurrent: "Current Network",
  DisplayBuild: "Out Of Network",
  DisplayEdits: "Edits",
};

export function ProvidersRoute({ mode }) {
  const networkId = useSelector(selectSelectedNetworkId);

  const { isFetching: isFetchingMemberServed } = useGetMemberServedQuery(
    { networkId },
    { skip: !networkId }
  );

  const { isFetching: isFetchingScores } = useGetProviderScoresQuery(
    { networkId },
    { skip: !networkId }
  );

  const { isFetching: isFetchingProviderUniverse } =
    useGetProviderUniverseQuery({ networkId }, { skip: !networkId });

  const { isFetching: isFetchingProviderInStatus } =
    useGetProviderStatusesQuery({ networkId }, { skip: !networkId });

  const { isFetching: isFetchingProviderBaselineInStatus } =
    useGetProviderStatusesQuery(
      { networkId, baseline: 1 },
      { skip: !networkId }
    );

  const { isFetching: isFetchingProviderLocationTags } =
    useGetProviderLocationTagsQuery(networkId, { skip: !networkId });

  const baselineChanges = useSelector(selectEditedProviders);

  const isLoading =
    isFetchingProviderUniverse ||
    isFetchingProviderInStatus ||
    isFetchingProviderBaselineInStatus ||
    isFetchingScores ||
    isFetchingProviderLocationTags ||
    isFetchingMemberServed;

  return (
    <WhenNetworks>
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h4" sx={{ margin: "24px 16px" }}>
          {modeTitleMap[mode]}
        </Typography>
        <ProviderTable key={mode} mode={mode} isLoading={isLoading} baselineChanges={baselineChanges}/>
      </div>
    </WhenNetworks>
  );
}
