import { createApi } from "@reduxjs/toolkit/query/react";
import { fetcher } from "./fetcher";
import { postFileUpdateApi } from "./handlers";

export const selfServeApi = createApi({
  reducerPath: "selfServeApi",
  keepUnusedDataFor: 7200,
  baseQuery: fetcher,
  endpoints: (build) => ({
    postFileUpdate: postFileUpdateApi(build),
  }),
});

export const {
  usePrefetch,
  usePostFileUpdateMutation,
} = selfServeApi;
