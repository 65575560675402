import { Stack } from "@mui/system";
import { useSkipMarginalIncrementalSimulation } from "../../hooks/useSkipMarginalIncrementalSimulation";
import { AdequacyChip } from "./AdequacyChip";
import { getAdequacyPerSpecialtyAndCounty } from "./utils";

export function AdequacyCell(props) {
  const { id, row, api } = props;
  const skipMarginalIncrementalSimulation = useSkipMarginalIncrementalSimulation();

  let adequacies = getAdequacyPerSpecialtyAndCounty(props);

  const focusCountyFilter = api?.state?.filter?.filterModel?.items?.find(
    (i) =>
      i.field === "adequacy_per_county" &&
      i.operator === "isFocusCounty" &&
      i.value?.length > 0
  );
  if (focusCountyFilter)
    adequacies = adequacies.filter((a) =>
      focusCountyFilter.value.some((str) =>
        a.countyName.toLowerCase().includes(str.toLowerCase())
      )
    );

  if (skipMarginalIncrementalSimulation && !row.is_baseline_network) return <span>Not Available</span>;
  if (!adequacies.length) return <span>no adequacy impact</span>;

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      height="100%"
      sx={{ overflowX: "scroll" }}
    >
      {adequacies.map((item) => (
        <AdequacyChip
          key={`${id}-${item.specialtyId}-${item.countyId}`}
          isInn={row.is_inn}
          item={item}
        />
      ))}
    </Stack>
  );
}
